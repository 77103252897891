/**
 *
 */
const calendarPickers = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';

	var DATE_PICKER_SELECTOR = '.date-input:enabled:not([readonly]), .date-input-readonly';

	/* global loadjs */
	function setupCalendarPickers ($html) {
		var $inputs = ($html ? $html.find(DATE_PICKER_SELECTOR) : $(DATE_PICKER_SELECTOR));

		if ($inputs.length === 0) return;

		// @ts-ignore
		if (!loadjs.isDefined('daterangepicker')) {
			// @ts-ignore
			loadjs(['../assets/vendor/daterangepicker-jquery-upgrade-3.6/daterangepicker.js', '../assets/vendor/daterangepicker-jquery-upgrade-3.6/daterangepicker.css'], 'daterangepicker', { async: false });
		}

		// @ts-ignore
		loadjs.ready('daterangepicker', function dateRangePickerHasLoaded () {
			initCalendarPickers($html);
		});
	}

	function initCalendarPickers ($html) {
		var $inputs = ($html ? $html.find(DATE_PICKER_SELECTOR) : $(DATE_PICKER_SELECTOR));

		$inputs.each(initCalendarInput);
	}
     function initCalendarInput(i, el){
          var $dateInput = $(el);
			var format = $dateInput.attr('data-format');
			var defaultFormat = 'YYYY-MM-DD';
			var isRange = $dateInput.hasClass('date-range-input');
			var showDropdowns = getShowDropdowns($dateInput.attr('data-show-dropdown'));
			var minDays = $dateInput.attr('data-mindate');
			var maxDays = $dateInput.attr('data-maxdate');
			var ranges = getRanges(isRange);
			var options;
			var start;
			var end;
			var datePicker;

			if (!format) format = defaultFormat;

			options = {
                autoUpdateInput: false,
                autoApply:true,
                showCustomRangeLabel:false,
				showDropdowns:showDropdowns,
                alwaysShowCalendars: true,
				singleDatePicker: !isRange,
				locale: {
					cancelLabel: 'Clear',
					format: format
				}
            };
            
            if(isRange) options.ranges = ranges;

			if (minDays) {
				minDays = minDays.indexOf('-') >= 0 ? minDays.substring(1) : minDays;
				// @ts-ignore
				start = moment().subtract(minDays, 'days');
				// @ts-ignore
				options.minDate = start;
			}

			if (maxDays) {
				maxDays = maxDays.indexOf('+') >= 0 ? maxDays.substring(1) : maxDays;
				// @ts-ignore
				end = moment().add(maxDays, 'days');
				// @ts-ignore
				options.maxDate = end;
			}

			// @ts-ignore
			datePicker = $dateInput.daterangepicker(options);

			datePicker.on('apply.daterangepicker', function (ev, picker) {
				if (isRange) {
					$(this).val(picker.startDate.format(format) + ' ' + picker.endDate.format(format));

					return;
				}

				$(this).val(picker.startDate.format(format));
                    $(this).trigger('change');
			});
            jQuery(this).attr('autocomplete','off');
			datePicker.on('cancel.daterangepicker', function (ev, picker) {
				$(this).val('');
			});
     }
	function getShowDropdowns (showDropdowns) {
		if (showDropdowns == 'true') return true;

		return false;
	}

	/* globals moment */
	function getRanges (isRange) {
		if (!isRange) return {};

		return {
			// @ts-ignore
			'Today': [moment(), moment()],
			// @ts-ignore
			'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
			// @ts-ignore
			'Last 7 Days': [moment().subtract(6, 'days'), moment()],
			// @ts-ignore
			'Last 30 Days': [moment().subtract(29, 'days'), moment()],
			// @ts-ignore
			'This Month': [moment().startOf('month'), moment().endOf('month')],
			// @ts-ignore
			'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
		};
	}

	return {
		setupCalendarPickers: setupCalendarPickers,
          initCalendarInput:initCalendarInput
	};
}(window, jQuery));

/**
 *
 */
var ajaxReturnData = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	/**
	 * Use Ajax to get retrieval data and fill maintainer fields. (2012-08-22)
	 * The input field should have this form:
	 * <input data-localfields="MNTFLD1,MNTFLD2,MNTFLD3,MNTFLD4..."
	 *        data-remotefields="RETFLD1,RETFLD2,RETFLD3,RETFLD4..."
	 *        data-rowindex="${row_index}"
	 *        mrclookup="MRCJAVA2.I00051s"
	 *        mrclookupfield="ORDNUM"
	 *        name="ORDNUM" title="ORDNUM" type="text" value="${row.ORDNUM?html}" />
	 *
	 * To use this plugin:
	 *    jQuery("input[data-remotefields]").ajaxAutoFill();
	 */
    function parseResponse(responseText){
        var matchrowStr = getJson(responseText," matchRow=",";");
        matchrowStr = matchrowStr.replace(/\\'/g,"_quot");
        matchrowStr = matchrowStr.replace(/'/g,'"');
        matchrowStr = matchrowStr.replace(/_quot/g,"'");
        var matchRow = JSON.parse(matchrowStr);
        return matchRow;
    }
    function getJson(responseText, begin, end){
        var pos1 = responseText.indexOf(begin) + begin.length;
        var pos2 = responseText.lastIndexOf(end);
        return responseText.substring(pos1, pos2);
    
    }
	function ajaxAutoFill () {
        return this.each(function(){
            if(jQuery(this).prop("tagName") == 'SPAN'){
                var selectEl = jQuery(this).find('select').get(0);
                var spanEl = this;
                jQuery(this).on('change', 'select', function(ev){
                    changeEvent(ev,this, spanEl);
                });
               // jQuery(selectEl).change(function(ev){
                //    changeEvent(ev,selectEl, spanEl);
               // });
                return;
            }
            jQuery(this).blur(function(ev){
                changeEvent(ev, this, this);
            });
        });
        
	}
    function changeEvent(ev, inputEl, dataEl){
        var table = jQuery(inputEl).parentsUntil('table').parent(); // used in process ajax data
        var tr = jQuery(inputEl).parentsUntil('tr').parent();
        var trInputs = tr.find(':input');
        var lookupTable = jQuery(dataEl).attr('mrclookup');
        var mnt_flds = jQuery(dataEl).attr('data-localfields'); // fields to apply ajax data to
        var ret_flds = jQuery(dataEl).attr('data-remotefields'); // name of fields to lookup in retrieval
        var fldName = jQuery(dataEl).attr('mrclookupfield');
        var fldVal = jQuery(inputEl).val();

        if (fldVal === '') return;

        var url = lookupTable + '?reqtype=101&slnk=1&' +  encodeURIComponent(fldName) + '=' + encodeURIComponent(fldVal);

        jQuery.ajax({
            url: url,
            dataType: 'html',
            /* global matchRow */
            success: function (data, textStatus) { // Ajax success!
                /* jshint -W061 */
                var matchRow = parseResponse(data); // set var availOptions
                /* jshint +W061 */
                // @ts-ignore
                onSuccess(matchRow);
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) { // Ajax failed
                var errmsg = 'Error happened during Ajax request: ';
                errmsg += XMLHttpRequest.responseText;
                alert(errmsg);
            }
        });

        /**
         * Ajax success, fill input values with return data: {ORDLIN:'12345', PRDNO:'opopop',...}
         */
        function onSuccess (matchRow) {
            var ajaxValues = matchRow; // {ORDLIN:'12345', PRDNO:'opopop'};
            var locals = mnt_flds.split(',');
            var remotes = ret_flds.split(',');

            if (locals.length !== remotes.length) {
                alert('data-localfields and data-remotefields do not match!');

                return;
            }

            var inputs = trInputs;

            if (inputs.length === 1) {
                inputs = jQuery(table).find(':input'); // Single Maintainer
            }

            jQuery(inputs).each(function (eachIndex, el) {
                var $input = jQuery(el);
                var name = $input.attr('name');
                var rowi = null;
                if (typeof name == 'undefined'){
                    return true;
                }
                var pos = name.indexOf('_row'); // radio/checkbox in grids

                if (pos > 0) rowi = name.substring(pos);

                for (var i = 0; i < locals.length; i++) {
                    var local = locals[i];

                    // For radio/checkbox, add _rowindex to name
                    if (rowi) local += rowi;

                    if (local !== name) continue;

                    // This mnt fld name matches local fld name, assign value
                    var remote = remotes[i];
                    var val = ajaxValues[remote];
                    var type = $input.attr('type');

                    if (type && (type == 'radio' || type == 'checkbox')) { // radio/checkbox
                        var rval = $input.val();

                        if (rval === val) {
                            $input.prop('checked', true);
                        } else if (type === 'checkbox') {
                            $input.prop('checked', false);
                        }
                    } else { // input
                        $input.val(val);
                    }
                }
            });
        }
    }
	// @ts-ignore
	jQuery.fn.ajaxAutoFill = ajaxAutoFill;

	/**
	 * Set row number for Grid Add maintainer
	 * 'Lookup'    return data: a[mrc=returndata],
	 * 'Smartlink' return data: .rtnlnk
	 */
	/* global rownum0: true */ // eslint-disable-line no-unused-vars
	function setGridAddRowNumber () {
		jQuery(this).click(function (ev) { // Set row number for return data of Lookup
			var tr = jQuery(this).parentsUntil('tr').parent();
			var input1 = tr.find('input').eq(0);
			var row = input1.attr('data-rowindex');

			// @ts-ignore
			rownum0 = row; // Defined in skeleton
		});
	}

	// @ts-ignore
	jQuery.fn.setRowNumberForReturnData = setGridAddRowNumber;

	return {};
}(window, jQuery));
